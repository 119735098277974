<template>
  <div>
    <LoadingBanner
      v-bind:title="'User Registration'" 
      v-bind:isLoading="isLoading"></LoadingBanner>

    <div class="alert alert-danger [ mt-3 ]" role="alert" v-if="errors.length > 0">
      There were errors on the page:
      <ul class="[ m-0 ]">
        <li v-for="(error) in errors" v-bind:key="error.id">
          {{ error.value }}
        </li>
      </ul>
    </div>

    <div class="alert alert-success" role="alert" v-if="isConfirmEmail">
      An email has been sent to {{ user.email }}. Please click the link the email to confirm your account.
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <form @submit.prevent="register" class="form-horizontal">
          <div class="form-group">
            <label for="email">Email</label>
            <input v-model="user.email" v-bind:disabled="isPreAuth" type="text" name="email" id="email" class="form-control" placeholder="Email" aria-describedby="email">
          </div>
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input v-model="user.firstName" type="text" name="firstName" id="firstName" class="form-control" placeholder="First Name" aria-describedby="firstName">
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input v-model="user.lastName" type="text" name="lastName" id="lastName" class="form-control" placeholder="Last Name" aria-describedby="lastName">
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input v-model="user.password" type="password" name="password" id="password" class="form-control" aria-describedby="password">
            <small v-if="isPreAuth" class="text-muted">Please choose a password.</small>
          </div>
          <div class="form-group">
            <label for="passwordRepeat">Confirm Password</label>
            <input v-model="user.passwordConfirm" type="password" name="passwordRepeat" id="passwordRepeat" class="form-control" aria-describedby="passwordRepeat">
            <small v-if="isPreAuth" class="text-muted">Please confirm password.</small>
          </div>
          <div class="form-group">
            <LoadingPanel v-if="isLoading"></LoadingPanel>
            <button v-else type="submit" class="btn btn-primary" role="button">Submit</button>
          </div>
          <div class="form-group">
            Already have an account? <a href="/login">Sign In</a>
          </div>
        </form>    
      </div>
    </div>
  
    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MessageDisplay from '@/components/MessageDisplay'
import LoadingBanner from '@/components/LoadingBanner'
import LoadingPanel from "@/components/LoadingPanel"
import { default as usersAPI } from '@/api/UsersAPI'
import { setErrorMessage } from '@/utils'

export default {
  name: 'UserRegisterContainer',

  components: {
    'MessageDisplay': MessageDisplay,
    'LoadingBanner': LoadingBanner,
    'LoadingPanel': LoadingPanel
  },

  data () {
    return {
      isLoading: false,
      isPreAuth: false,
      isConfirmEmail: false,
      errorMessage: null,
      successMessage: null,
      errors: [],
      user: {}
    }
  },

  mounted () {
    this.authRegistration()
  },

  methods: {
    ...mapActions([
      'login'
    ]),

    authRegistration: function() {
      this.isLoading = true;
      usersAPI
        .authRegistration(this.getTokenParam())
        .then((user) => {
          if (user) {
            this.user = user
            this.isPreAuth = true
          }
        })
        .catch(() => this.$router.push({ name: 'login' }))
        .finally(() => this.isLoading = false)
    },

    register: function() {
      this.isLoading = true
      usersAPI
        .register(this.user)
        .then((response) => {

          if (!this.isPreAuth) {
            this.isConfirmEmail = true
          } else {
            if (response) {
              this.login(response)
              this.$router.push({ name: 'assetUpload' })
            }
          }
        })
        .catch((errors) => {
          this.errors = errors.body.errors
        })
        .finally(() => this.isLoading = false)
    },

    getTokenParam: function() {
      return this.$route.params.token
    }
  }
}
</script>

<style>

</style>